import React, { useContext } from "react";
import PropTypes from "prop-types";

import { GlobalStateContext } from "../context/GlobalContextProvider";

const Text = ({ en, vn, ...other }) => {
  const state = useContext(GlobalStateContext) || {};

  return <span {...other}>{state.language === "en" ? en : vn}</span>;
};

Text.defaultProps = {
  en: "",
  vn: "",
};

Text.propTypes = {
  en: PropTypes.string,
  vn: PropTypes.string,
};

export default Text;
