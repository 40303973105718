import React, { useContext, useEffect } from "react";
import { navigate } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

import heart from "../images/undraw_medicine.svg";
import home from "../images/undraw_at_home.svg";
import Text from "../components/Text";

import { GlobalStateContext } from "../context/GlobalContextProvider";

function SummaryPage() {
  const state = useContext(GlobalStateContext) || {};

  useEffect(() => {
    if (!state.submitted) {
      console.log("form not submitted, redirecting to home");
      navigate("/");
    }
  });

  return (
    <>
      <Layout>
        <SEO
          keywords={[`Don Bosco`, `COVID`, `screening`, `safety`]}
          title="DBCS | Submitted"
        />
        <div className="flex h-full flex-col">
          <div className={`h-full py-4 md:py-8 px-12 `}>
            <div className="container mx-auto px-4 py-4 pb-12  h-full bg-white rounded text-center ">
              {state.success ? (
                <div>
                  <img
                    alt="heart"
                    className="block w-1/2 mx-auto mb-4 mt-2"
                    src={heart}
                  />
                  <h2 className="text-2xl mb-2">
                    <Text en="Thank you!" vn="Cám ơn!" />
                  </h2>
                  <p className="my-2 text-2xl font-semibold">
                    {state.dateApplied}
                  </p>
                  <p className="mb-2">
                    <Text
                      en="The following students are cleared for entry:"
                      vn="Những em có tên dưới đây đã được chấp thuận cho vào:"
                    />
                  </p>
                  {state.names.map((name) => (
                    <div
                      key={`${name.firstName}${name.lastName}${Math.floor(
                        Math.random() * 1000
                      )}`}
                      className="text-3xl"
                    >{`${name.firstName} ${name.lastName}`}</div>
                  ))}
                  <br />
                  <br />
                  <div className="flex justify-center">
                    <div className="flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="h-8"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
                        />
                      </svg>
                      <span className="ml-1">
                        <Text en="Tip:" vn="Chỉ dẫn:" />
                      </span>
                      <p className="text-left ml-3 font-light text-sm">
                        <Text
                          en="Screenshot this page to make it easier to access later."
                          vn="Chụp hình trang này đễ tiện cho sau này."
                        />
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <img
                    alt="heart"
                    className="block w-1/2 mx-auto mb-4 mt-2"
                    src={home}
                  />
                  <h2 className="text-2xl font-bold mb-2">
                    <Text en="Sorry!" vn="Thành thật xin lỗi!" />
                  </h2>
                  <p className="my-2 font-semibold text-2xl">
                    {state.dateApplied}
                  </p>
                  <p className="mb-2">
                    <Text
                      en="For the safety of others and to help prevent the spread of COVID-19, please stay home."
                      vn="Vì sự bảo an cho những người khác và đễ tránh lây lan cơn dịch bệnh COVID-19, xin vui lòng ở nhà."
                    />
                  </p>
                  <p>
                    <Text
                      en="Thank you for your understanding and cooperation."
                      vn="Cám ơn sự thông cảm và sự cộng tác của quý vị."
                    />
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default SummaryPage;
